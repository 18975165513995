.form-login {
  background-color: "#e2e8f0";
  width: 50%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .form-login {
    width: 100%;
  }
}
