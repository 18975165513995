body {
  height: auto !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 10px;
}

.nav.items {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.employee-name {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-right: 5px;
}

.items {
  display: flex;
  justify-content: space-between;
}

.profile {
  margin-left: 20px;
}

.nav .logo img {
  width: 48px;
}

.menu > li {
  float: left;
  margin: 1px;
  background-color: #cac2c265;
  height: 30px;
  width: 100px;
}

.menu > li a {
  display: block;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-weight: bold;
  color: white;
}

.menu > li a:hover {
  background-color: #3a3636;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}

.item-pedido-field .ui.input > input {
  font-weight: bold;
  width: 50px;
}

.item-pedido-field.decimal .ui.input > input {
  font-weight: bold;
  width: 60px !important;
}

.item-pedido-field input {
  text-align: right !important;
}

.ui.grid {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.progress {
  height: 0.4rem !important;
}

.ui.progress:last-child {
  margin: 0 0 0em !important;
}

.sub-menu {
  display: none;
  position: absolute;
  z-index: 10;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
  visibility: hidden;
  overflow: hidden;
}

.menu li:hover > .sub-menu {
  display: block;
}

.sub-menu > li {
  background-color: #6c6868;
}

.mobile-menu {
  display: none;
}

.menu-mobile-items {
  position: absolute;
  top: -330px;
  background-color: #343a40;
  width: 100%;
  left: 0;
  z-index: 10;
  border-radius: 4px;
  display: none;
  transition: 0.5s;
}

.menu-mobile-items .menu.clearfix {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.menu-mobile-items .clearfix > li a {
  text-align: left;
}

.menu-mobile-items .clearfix {
  display: none;
}

.menu-mobile-items .sub-menu-mobile li a {
  padding-left: 20px;
}

.menu-mobile-items .clearfix > li {
  border-bottom: 1px solid #545454;
  background-color: #343a40;
  width: auto;
  height: auto;
}

.slide-mobile-menu-items {
  transform: translateY(383px);
}

.menu-mobile-items .sub-menu-mobile {
  display: none;
}

/*
    Responsivo
*/
@media only screen and (max-width: 600px) {
  .items {
    display: none;
  }

  .menu-mobile-items .sub-menu-mobile {
    display: block;
  }

  .mobile-menu {
    display: flex;
  }

  .menu-mobile-items {
    display: block;
  }

  .employee-name {
    font-size: 10px;
  }
}
